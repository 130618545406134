export class RatesModel {
  siteGuid: string;
  userGuid: string;
  date: number;
  tier1Cost: number;
  tier2Cost: number;
  tier3Cost: number;
  embeddedTier1Cost: number;
  embeddedTier2Cost: number;
  embeddedTier3Cost: number;
}
