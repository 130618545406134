import { Injectable } from '@angular/core';

// Router
import { Router } from '@angular/router';

// Services
import { UserService } from '@app/modules/core/services/user.service';

const AUTH_URL = '/';

@Injectable()
export class AuthService {
  constructor(
    private readonly userService: UserService,
    private readonly route: Router
  ) {
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  isAuthenticated(): boolean {
    const token = this.getToken();

    return !!token;
  }

  auth({ token, roles, countMethod, utmLabel, ...data }) {
    this.userService.setToken(token);
    this.userService.setUserAdmin(roles);
    this.userService.setCountMethod(countMethod);
    this.userService.setUserName(utmLabel);

    this.route.navigate([AUTH_URL]);
  }
}
