import { Component } from '@angular/core';

// Services
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Components
import { ContactUsComponent } from '@app/modules/shared/components/modals/contact-us/contact-us.component';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})

export class FooterComponent {
  constructor(
    private readonly modalService: NgbModal
  ) {
  }

  userContactUs(): void {
    this.modalService.open(ContactUsComponent, {
      keyboard: true,
      size: '420',
      backdrop: true,
      centered: true
    })
  }
}
