import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Service
import { HttpService } from '@app/modules/core/services/http.service';
import { PasswordChangeForm } from '@app/modules/change-password/interfaces/password-change-form';
import { ConfigResolverService } from '@app/modules/core/services/config-resolver.service';

@Injectable()
export class LoginService {
  private readonly httpUrlCp: string;

  constructor(
    private readonly httpService: HttpService,
    private readonly configStorageService: ConfigResolverService,
  ) {
    this.httpUrlCp = this.configStorageService.get('httpURLCP');
  }

  loginCP({ login, password }: { login: string; password: string }): Observable<any> {
    const body = {
      username: login,
      password_hash: password
    };

    return this.httpService.send(
      {
        url: 'login',
        method: 'POST',
        host: this.httpUrlCp,
        body
      });
  }

  login({ login, password }: { login: string; password: string }): Observable<any> {
    const body = {
      login,
      password
    };

    return this.httpService.send(
      {
        url: 'auth/login',
        method: 'POST',
        body
      });
  }

  restorePassword({email, recaptchaToken}: {email: string; recaptchaToken: string}): Observable<any> {
    const body = {
      email,
      recaptchaToken
    };

    return this.httpService.send(
      {
        url: 'auth/recovery-password',
        method: 'POST',
        body
      });
  }

  changePassword(formData: PasswordChangeForm): Observable<any> {

    return this.httpService.send(
      {
        url: 'auth/change-password',
        method: 'POST',
        body: formData
      });
  }
}
