import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// RxJs
import { finalize } from 'rxjs/operators';

// Interfaces
import { SignUpForm } from '@app/modules/shared/interfaces/sign-up-form';
import { SignUpCpForm } from '@app/modules/shared/interfaces/sign-up-cp-form';

// Services
import { RegistrationService } from '@app/modules/core/services/registration.service';
import { LoginService } from '@app/modules/core/services/login.service';
import { AuthService } from '@app/modules/core/services/auth.service';
import { ConfigResolverService, UserService } from '@app/modules/core';

// Constants
import { MIN_PAYOUT } from '@app/modules/shared/constants/min-payout';
import { PAYMENT_METHODS } from '@app/modules/shared/constants/payment-methods';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']
})

export class RegisterComponent implements OnInit {
  private redirectUrl: string;

  public isAdmin: boolean;
  public isLoading = false;

  public form: FormGroup;
  public formCp: FormGroup;
  public error: string;

  public captchaKey: string;
  public captchaKeyCp: string;

  MIN_PAYOUT = MIN_PAYOUT;
  PAYMENT_METHODS = PAYMENT_METHODS;

  isPassword = true;

  public SOURCES = {
    cp: 'cp',
    bt: 'bt'
  };

  public currentSource = 'bt';

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly route: Router,
    private readonly regService: RegistrationService,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly configResolver: ConfigResolverService,
    private readonly userService: UserService
  ) {
    this.redirectUrl = this.configResolver.get('redirectUrl');
    this.captchaKey = this.configResolver.get('recaptchaKey');
    this.captchaKeyCp = this.configResolver.get('recaptchaKeyCP');
  }

  private initForm(): void {
    this.form = this.fb.group({
      login: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      name: [''],
      skype: [''],
      telegram: [''],
      recaptchaToken: ['', Validators.required],
      paymentType: ['paxum'],
      minPayment: [10000],
      paxumEmail: ['', [Validators.email, Validators.required]],
      usdtTRC20: [''],
      usdtERC20: [''],
    });

    this.formCp = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      password_hash: ['', Validators.required],
      recaptchaToken: ['', Validators.required],
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    });

    this.form.controls['paymentType'].valueChanges.subscribe(() => {
      this.form.controls["paxumEmail"].clearValidators();
      this.form.controls["usdtTRC20"].clearValidators();
      this.form.controls["usdtERC20"].clearValidators();

      if (this.form.controls['paymentType'].value === 'paxum') {
        this.form.controls["paxumEmail"].setValidators([Validators.email, Validators.required]);
      } else if (this.form.controls['paymentType'].value === 'usdt trc20') {
        this.form.controls["usdtTRC20"].setValidators([Validators.required]);
      } else if (this.form.controls['paymentType'].value === 'usdt erc20') {
        this.form.controls["usdtERC20"].setValidators([Validators.required]);
      }

      this.form.controls["paxumEmail"].updateValueAndValidity();
      this.form.controls["usdtTRC20"].updateValueAndValidity();
      this.form.controls["usdtERC20"].updateValueAndValidity();
    })

    this.formCp.valueChanges.subscribe(() => {
      this.resetError();
    })
  }

  public setCurrentSource(source: string) {
    this.currentSource = source;
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  passwordViewToggle(): void {
    this.isPassword = !this.isPassword;
  }

  onSubmit({ value, valid }: { value: SignUpForm, valid: boolean }): void {
    if (valid) {
      const request = this.regService.register(this.form.getRawValue());

      request
        .pipe(
          finalize(() => {this.isLoading = false})
        )
        .subscribe({
          next: () => {
            // window[ 'grecaptcha' ].reset();

            if (!this.isAdmin) {
              this.onRegisterSuccess({ login: value.login, password: value.password });
            } else {
              this.activeModal.close();
            }
          },
          error: (err) => {
            // window[ 'grecaptcha' ].reset();
            // this.form.controls.recaptchaToken.setValue('');
            this.error = err.error;
          }
        });
    }
  }

  onSubmitCp({ value, valid }: { value: SignUpCpForm, valid: boolean }): void {
    if (valid) {
      const request = this.regService.registerCP(this.formCp.getRawValue());

      request
        .pipe(
          finalize(() => {this.isLoading = false})
        )
        .subscribe({
          next: () => {
            // window[ 'grecaptcha' ].reset();

            if (!this.isAdmin) {
              this.onRegisterCpSuccess({ login: value.username, password: value.password_hash });
            } else {
              this.activeModal.close();
            }
          },
          error: (err) => {
            // window[ 'grecaptcha' ].reset();
            // this.form.controls.recaptchaToken.setValue('');
            this.error = err.error;
          }
        });
    }
  }

  onRegisterSuccess({ login, password }: { login: string; password: string }): void {
    const request = this.loginService.login({ login, password });

    request
      .subscribe({
        next: (res) => {
          this.authService.auth(res.data);
          this.activeModal.close();
        },
        error: () => {
        }
      });
  }

  onRegisterCpSuccess({ login, password }: { login: string; password: string }): void {
    const request = this.loginService.loginCP({ login, password });

    request
      .subscribe({
        next: (res) => {
          const url = `${this.redirectUrl}?token=${res.data.token}`;

          window.open(url, '_top');
        },
        error: () => {
        }
      });
  }

  navigate(link: string) {
    this.closeModal();
    this.route.navigate([link]);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.userService.isAdminSubject.subscribe(value => this.isAdmin = value);
    this.initForm();
  }
}
