import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { HttpService } from '@app/modules/core';

// Model
import { WebmasterModel } from '@app/modules/admin-webmasters/models/webmaster-model';

// Interface
import { WebmasterEditForm } from '@app/modules/shared/interfaces/webmaster-edit-form';

@Injectable()
export class SelfService {

  constructor(
    private readonly httpService: HttpService
  ) {
  }

  getUserSelf(): Observable<any> {
    return this.httpService.send(
      {
        url: 'auth/self',
        method: 'GET'
      })
      .pipe(
        map((res) => plainToClass(WebmasterModel, res.data))
      );
  }

  getUserInfo(guid: string): Observable<any> {
    return this.httpService.send(
      {
        url: `users/${guid}`,
        method: 'GET'
      })
      .pipe(
        map((res) => plainToClass(WebmasterModel, res.data))
      );
  }

  setUserSelf(data: WebmasterEditForm): Observable<any> {
    return this.httpService.send(
      {
        url: 'auth/self',
        method: 'POST',
        body: data
      });
  }
}
