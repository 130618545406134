import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wait-content',
  templateUrl: 'wait-content.component.html',
  styleUrls: ['wait-content.component.scss']
})

export class WaitContentComponent {
  constructor(
    private readonly activeModal: NgbActiveModal
  ) {
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
