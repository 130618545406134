export const MIN_PAYOUT = [
  {
    title: '50',
    value: 5000
  },
  {
    title: '100',
    value: 10000
  },
  {
    title: '500',
    value: 50000
  },
  {
    title: '1000',
    value: 100000
  }
];
