import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: 'confirm-payment.component.html',
  styleUrls: ['confirm-payment.component.scss']
})

export class ConfirmPaymentComponent {
  constructor(
    private readonly activeModal: NgbActiveModal
  ) {
  }

  confirmModal(): void {
    this.activeModal.close();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
