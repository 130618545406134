import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mBites'
})

export class MBitesPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    return `${Math.ceil((value / 1000000) * 100) / 100} mb`;
  }
}
