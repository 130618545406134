import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {
  public transform(value: number, withTime: boolean = false): string {
    const format = withTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY';
    return !!value ? moment(value).format(format) : '';
  }
}
