import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthYearFormat'
})

export class MonthYearFormatPipe implements PipeTransform {
  public transform(value: number): string {
    return !!value ? moment(value).format('MMMM YYYY') : '';
  }
}
