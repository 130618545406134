import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IsAuthGuard } from '@app/modules/shared/guards/is-auth-guard';
import { IsAnonGuard } from '@app/modules/shared/guards/is-anon-guard';
import { IsAdminGuard } from '@app/modules/shared/guards/is-admin-guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/stats',
    pathMatch: 'full'
  },
  {
    path: 'admin-sites',
    loadChildren: () => import('./modules/admin-sites/admin-sites.module').then(mod => mod.AdminSitesModule),
    canLoad: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivate: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivateChild: [
      IsAuthGuard,
      IsAdminGuard
    ]
  },
  {
    path: 'admin-webmasters',
    loadChildren: () => import('./modules/admin-webmasters/admin-webmasters.module').then(mod => mod.AdminWebmastersModule),
    canLoad: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivate: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivateChild: [
      IsAuthGuard,
      IsAdminGuard
    ]
  },
  {
    path: 'stats',
    loadChildren: () => import('./modules/stats/stats.module').then(mod => mod.StatsModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'full-dump',
    loadChildren: () => import('./modules/full-dump/full-dump.module').then(mod => mod.FullDumpModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'feed-master',
    loadChildren: () => import('./modules/feed-master/feed-master.module').then(mod => mod.FeedMasterModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'payments',
    loadChildren: () => import('./modules/payments/payments.module').then(mod => mod.PaymentsModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'terms',
    loadChildren: () => import('./modules/terms/terms.module').then(mod => mod.TermsModule)
  },
  {
    path: 'sites',
    loadChildren: () => import('./modules/sites/sites.module').then(mod => mod.SitesModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/change-password/change-password.module').then(mod => mod.ChangePasswordModule)
  },
  {
    path: 'promo',
    loadChildren: () => import('./modules/promo/promo.module').then(mod => mod.PromoModule),
    canLoad: [
      IsAnonGuard
    ],
    canActivate: [
      IsAnonGuard
    ],
    canActivateChild: [
      IsAnonGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
