import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

// Guards
import { IsAnonGuard } from '@app/modules/shared/guards/is-anon-guard';
import { IsAuthGuard } from '@app/modules/shared/guards/is-auth-guard';
import { IsAdminGuard } from '@app/modules/shared/guards/is-admin-guard';

// Components
import * as components from './components';

// Pipes
import * as pipes from './pipes';

// Services
import * as services from './services';

const sharedComponents = [
  components.HeaderComponent,
  components.FooterComponent,
  components.RegisterComponent,
  components.LoginComponent,
  components.ContactUsComponent,
  components.IconComponent,
  components.InputComponent,
  components.SelectComponent,
  components.SiteSelectComponent,
  components.AddButtonComponent,
  components.LoadingIndicatorComponent,
  components.WaitContentComponent,
  components.ConfirmPaymentComponent,
  components.RestorePasswordComponent,
  components.ConfirmRestorePasswordComponent,
  components.UserEditComponent,

  pipes.ValuePipe,
  pipes.DateFormatPipe,
  pipes.MBitesPipe,
  pipes.MonthYearFormatPipe
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: sharedComponents,
  exports: sharedComponents,
  providers: [
    IsAuthGuard,
    IsAnonGuard,
    IsAdminGuard,

    services.SitesService,
    services.ThumbsService,
    services.SelfService,
    services.ContactUsService
  ],
  entryComponents: [
    components.RegisterComponent,
    components.LoginComponent,
    components.ContactUsComponent,
    components.WaitContentComponent,
    components.ConfirmPaymentComponent,
    components.RestorePasswordComponent,
    components.ConfirmRestorePasswordComponent,
    components.UserEditComponent
  ]
})
export class SharedModule {
}
