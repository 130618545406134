export class WebmasterModel {
  guid: string;
  isLocked: boolean;
  utmLabel: string;
  name: string;
  email: string;
  skype: string;
  telegram: string;
  countMethod: string;
  collectStatistics: boolean;
  registered: number;
  paymentType: string;
  minPayment: number;
  paxumEmail: string;
  usdtTRC20: string;
  usdtERC20: string
}
