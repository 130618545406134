import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad, Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from '@app/modules/core';

const DEFAULT_ROUTE = '';

@Injectable()
export class IsAnonGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {
  }

  private isAnon(): boolean {
    if (!this.userService.isLogged()) {
      return true;
    }

    this.router.navigate([DEFAULT_ROUTE]);

    return false;
  }

  canLoad(route: Route): boolean {
    return this.isAnon();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAnon();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAnon();
  }
}
