import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Interfaces
import { WebmasterEditForm } from '@app/modules/shared/interfaces/webmaster-edit-form';

// Services
import { SelfService } from '@app/modules/shared/services';

// Constants
import { MIN_PAYOUT } from '@app/modules/shared/constants/min-payout';
import { PAYMENT_METHODS } from '@app/modules/shared/constants/payment-methods';

// Models
import { WebmasterModel } from '@app/modules/admin-webmasters/models/webmaster-model';

@Component({
  selector: 'app-user-edit',
  templateUrl: 'user-edit.component.html',
  styleUrls: ['user-edit.component.scss']
})

export class UserEditComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  isPassword = false;

  MIN_PAYOUT = MIN_PAYOUT;
  PAYMENT_METHODS = PAYMENT_METHODS;

  webmaster: WebmasterModel;

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly selfService: SelfService
  ) {
  }

  getSelfInfo(): void {
    this.selfService.getUserSelf()
      .subscribe((res) => {
        this.webmaster = res;

        this.initForm();
      })
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: [this.webmaster.email || '', [Validators.email, Validators.required]],
      password: [''],
      name: [this.webmaster.name || ''],
      skype: [this.webmaster.skype || ''],
      telegram: [this.webmaster.telegram || ''],
      paymentType: [this.webmaster.paymentType || 'paxum'],
      minPayment: [this.webmaster.minPayment || 10000],
      paxumEmail: [this.webmaster.paxumEmail || '', [Validators.email, Validators.required]],
      usdtTRC20: [this.webmaster.usdtTRC20 || ''],
      usdtERC20: [this.webmaster.usdtERC20 || ''],
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    })

    this.form.controls['paymentType'].valueChanges.subscribe(() => {
      this.form.controls["paxumEmail"].clearValidators();
      this.form.controls["usdtTRC20"].clearValidators();
      this.form.controls["usdtERC20"].clearValidators();

      if (this.form.controls['paymentType'].value === 'paxum') {
        this.form.controls["paxumEmail"].setValidators([Validators.email, Validators.required]);
      } else if (this.form.controls['paymentType'].value === 'usdt trc20') {
        this.form.controls["usdtTRC20"].setValidators([Validators.required]);
      } else if (this.form.controls['paymentType'].value === 'usdt erc20') {
        this.form.controls["usdtERC20"].setValidators([Validators.required]);
      }

      this.form.controls["paxumEmail"].updateValueAndValidity();
      this.form.controls["usdtTRC20"].updateValueAndValidity();
      this.form.controls["usdtERC20"].updateValueAndValidity();
    })
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  passwordViewToggle(): void {
    this.isPassword = !this.isPassword;
  }

  onSubmit({ value, valid }: { value: WebmasterEditForm, valid: boolean }): void {
    if (valid) {
      this.selfService.setUserSelf(value)
        .subscribe(
          (res) => {
            this.activeModal.close();
          },
          (err) => {
            this.error = err.error;
          })
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.getSelfInfo();
  }
}
