import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'value'
})

export class ValuePipe implements PipeTransform {
  public transform(value: number, symbol: string = '', withEnd = true, groupLength: number = 3): string {
    let end = '';
    let val;
    let result = '';

    if (!!symbol) {
      if (withEnd) {
        end = '.' + String(value % 100);
      }
      val = Math.floor(value / 100);
    } else {
      val = value;
    }

    let convertedValue = String(val);
    let length: number = convertedValue.length;
    const arr: string[] = [];

    while (length > 0) {
      length -= (length > groupLength) ? groupLength : length;
      arr.unshift(convertedValue.slice(length));
      convertedValue = convertedValue.slice(0, length);
    }

    result = !!symbol ? `${arr.join(' ')}${end} ${symbol}` : `${arr.join(' ')}`;
    return result;
  }
}
