import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  @Input()
  isLoading = false;

  constructor() {}

  onClick($event): void {
    $event.stopPropagation();
  }
}
