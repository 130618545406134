import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

// RxJs
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Services
import { UserService } from '@app/modules/core/services/user.service';
import { AuthService } from '@app/modules/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isNotChangePasswordUrl = window.location.pathname !== '/change-password';

  constructor(
    private readonly auth: AuthService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Authorization: `${this.auth.getToken()}`
      }
    });

    return next.handle(request)
      .pipe(
        catchError((error: HttpResponse<any>) => {
          if (error.status === 401 || error.status === 403) {
            this.userService.removeTokens();
            if (this.isNotChangePasswordUrl) {
              this.router.navigate(['/promo']);
            }
          }

          return throwError(error);
        })
      );
  }
}
