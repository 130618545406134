import { Component, OnDestroy, OnInit } from '@angular/core';
import scrollLock from 'scroll-lock';

// Modules
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { UserService } from '@app/modules/core';
import { Router } from '@angular/router';

// Components
import { RegisterComponent } from '@app/modules/shared/components/modals/register/register.component';
import { LoginComponent } from '@app/modules/shared/components/modals/login/login.component';
import { UserEditComponent } from '@app/modules/shared/components/modals/user-edit/user-edit.component';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public isAuth = false;
  public isAdmin = false;
  public userName = '';

  public currentPromo = 'partnership';

  isMobile = window.innerWidth < 1201;
  isMobileMenuOpen = false;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly modalService: NgbModal
  ) {
  }

  private authSubscribe(): void {
    this.userService.authenticated.subscribe(value => this.isAuth = value);
    this.userService.isAdminSubject.subscribe(value => this.isAdmin = value);
    this.userService.userNameSubject.subscribe(value => this.userName = value);
  }

  private routerSubscribe(): void {
    this.router.events.subscribe(() => {
      this.closeMobileMenu();
    })
  }

  userRegister() {
    this.closeMobileMenu();

    this.modalService.open(RegisterComponent, {
      keyboard: true,
      size: '420',
      backdrop: true,
      centered: true
    });
  }

  userLogin() {
    this.closeMobileMenu();

    this.modalService.open(LoginComponent, {
      keyboard: true,
      size: '420',
      backdrop: true,
      centered: true
    });
  }

  editUser() {
    this.closeMobileMenu();

    this.modalService.open(UserEditComponent, {
      keyboard: true,
      size: '420',
      backdrop: true,
      centered: true
    })
  }

  userLogout() {
    this.userService.removeTokens();
    this.router.navigate(['/promo']);
  }

  scrollToElement(element): void {
    this.closeMobileMenu();

    setTimeout(() => {
      const el = document.getElementById(element);
      this.currentPromo = element;
      el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 100);
  }

  closeMobileMenu(): void {
    if (this.isMobileMenuOpen) {
      this.isMobileMenuOpen = false;
      scrollLock.enablePageScroll();
    }
  }

  toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;

    if (this.isMobileMenuOpen) {
      scrollLock.disablePageScroll();
    } else {
      scrollLock.enablePageScroll();
    }
  }

  ngOnInit() {
    this.authSubscribe();
    this.routerSubscribe();
  }

  ngOnDestroy() {
    this.userService.authenticated.unsubscribe();
  }
}
