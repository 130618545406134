import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Services
import { HttpService } from '@app/modules/core';

// Interface
import { ContactUsForm } from '@app/modules/shared/interfaces/contact-us-form';


@Injectable()
export class ContactUsService {

  constructor(
    private readonly httpService: HttpService
  ) {
  }

  sendUserRequest(formData: ContactUsForm): Observable<any> {
    return this.httpService.send(
      {
        url: 'contacts',
        method: 'POST',
        body: formData
      })
  }
}
