export const PAYMENT_METHODS = [
  {
    title: 'paxum',
    value: 'paxum'
  },
  {
    title: 'USDT trc20',
    value: 'usdt trc20'
  },
  {
    title: 'USDT erc20',
    value: 'usdt erc20'
  }
];
