import { Type } from 'class-transformer';
import { CategoryModel } from '@app/modules/shared/models/category.model';

export class SiteCategoriesModel {
  @Type(() => CategoryModel)
  categories: CategoryModel[];

  nicheGuid: string;
  nicheTitle: string;
}
