import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-restore-password',
  templateUrl: 'confirm-restore-password.component.html',
  styleUrls: ['confirm-restore-password.component.scss']
})

export class ConfirmRestorePasswordComponent implements OnInit {
  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
  }
}
