import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Services
import { HttpService } from '@app/modules/core/services/http.service';
import { ConfigResolverService } from '@app/modules/core/services/config-resolver.service';

// Interfaces
import { SignUpForm } from '@app/modules/shared/interfaces/sign-up-form';
import { SignUpCpForm } from '@app/modules/shared/interfaces/sign-up-cp-form';

@Injectable()
export class RegistrationService {
  private readonly httpUrlCp: string;

  constructor(
    private readonly httpService: HttpService,
    private readonly configStorageService: ConfigResolverService,
  ) {
    this.httpUrlCp = this.configStorageService.get('httpURLCP');
  }

  register(formData: SignUpForm): Observable<any> {
    const body = formData;

    return this.httpService.send(
      {
        url: 'auth/register',
        method: 'POST',
        body
      });
  }

  registerCP(formData: SignUpCpForm): Observable<any> {
    const body = formData;

    return this.httpService.send(
      {
        url: 'register',
        method: 'POST',
        host: this.httpUrlCp,
        body
      });
  }
}
