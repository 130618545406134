import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  // viewProviders: [
  //   {
  //     provide: ControlContainer,
  //     useExisting: FormGroupDirective
  //   }
  // ]
})

export class InputComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  // TODO: вернуться к этому компоненту для чистки
  @Input() type = 'text';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() control: FormControl = new FormControl();
  @Input() isHalf = false;

  errors: Array<any> = ['This field is required'];

  @ViewChild('input', { static: false }) inputRef: ElementRef;

  constructor() {
  }

  private innerValue: any = '';

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  onChange(e: Event, value: any) {
    this.innerValue = value;
    this.propagateChange(this.innerValue);

    this.errors = [];

    for (const key in this.control.errors) {
      if (this.control.errors.hasOwnProperty(key)) {
        if (key === 'required') {
          this.errors.push('This field is required');
        } else {
          this.errors.push(this.control.errors[ key ]);
        }
      }
    }
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {

  }

  ngAfterViewInit() {
    this.control.valueChanges.subscribe(
      () => {
        if (this.control.value === '' || this.control.value === null || this.control.value === undefined) {
          this.innerValue = '';
          this.inputRef.nativeElement.value = '';
        }
      }
    );
  }

  ngOnChanges(): void {
  }
}
