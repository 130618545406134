import { RatesModel } from '@app/modules/shared/models/rates.model';
import { Type } from 'class-transformer';

export class SitesModel {
  guid: string;
  domain: string;
  isEnabled: boolean;
  isDeleted: boolean;
  viewId: string;
  tier1Cost: number;
  tier2Cost: number;
  tier3Cost: number;
  embeddedTier1Cost: number;
  embeddedTier2Cost: number;
  embeddedTier3Cost: number;
  isShowed: boolean;
  gaType: string;

  @Type(() => RatesModel)
  rates: RatesModel[];
}
