import { Injectable } from '@angular/core';

// RxJs
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {
  private token: string = null;
  private isUserAdmin: boolean = null;
  private countMethod: string = null;
  private userName: string = null;

  authenticated = new BehaviorSubject(false);
  isAdminSubject = new BehaviorSubject(false);
  userCountMethod = new BehaviorSubject('');
  userNameSubject = new BehaviorSubject('');

  constructor() {
    const parameters = new URLSearchParams(window.location.search);
    this.token = localStorage.getItem('token');
    if (!this.token && parameters.get('token')) {
      localStorage.clear();
      this.setToken(parameters.get('token'));
    }
    this.isUserAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    this.countMethod = localStorage.getItem('countMethod');
    this.userName = localStorage.getItem('name');

    this.authenticated.next(!!this.token);
    this.isAdminSubject.next(!!this.isUserAdmin);
    this.userCountMethod.next(this.countMethod);
    this.userNameSubject.next(this.userName);
  }

  setToken(token): void {
    localStorage.setItem('token', `Bearer ${token}`);

    this.token = `Bearer ${token}`;

    this.login();
  }

  setUserAdmin(roles) {
    const searchRole = 'admin';
    const isAdmin = !!(roles.find((el) => el === searchRole));

    this.isUserAdmin = isAdmin;

    localStorage.setItem('isAdmin', `${isAdmin}`);

    this.checkAdmin();
  }

  setCountMethod(countMethod) {
    localStorage.setItem('countMethod', `${countMethod}`);
    this.countMethod = countMethod;
    this.userCountMethod.next(this.countMethod);
  }

  setUserName(name) {
    localStorage.setItem('name', name);
    this.userName = name;
    this.userNameSubject.next(this.userName);
  }

  checkAdmin() {
    this.isAdminSubject.next(!!this.isUserAdmin);
  }

  getToken(): string {
    return this.token;
  }

  logout() {
    this.authenticated.next(false);
  }

  login() {
    this.authenticated.next(true);
  }

  removeTokens(): void {
    this.token = null;
    this.isUserAdmin = null;
    this.countMethod = null;
    this.userName = null;

    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('countMethod');
    localStorage.removeItem('name');

    this.logout();
  }

  isLogged(): boolean {
    return !!this.token;
  }

  isAdmin(): boolean {
    return this.isUserAdmin;
  }
}
