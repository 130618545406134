import { APP_INITIALIZER, NgModule } from '@angular/core';

// Services
import { LoginService } from '@app/modules/core/services/login.service';
import { UserService } from '@app/modules/core/services/user.service';
import { ConfigResolverService } from '@app/modules/core/services/config-resolver.service';
import { HttpService } from '@app/modules/core/services/http.service';
import { AppInitializerService } from '@app/modules/core/services/app-initializer.service';
import { AuthService } from '@app/modules/core/services/auth.service';
import { RegistrationService } from '@app/modules/core/services/registration.service';

export function initialize(appInitializer: AppInitializerService) {
  return () => appInitializer.init();
}

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppInitializerService],
      multi: true
    },
    UserService,
    LoginService,
    RegistrationService,
    ConfigResolverService,
    HttpService,
    AuthService
  ],
})
export class CoreModule {
}
